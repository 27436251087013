// Component Name : Iframe
// C1 CMS Name : Iframe

import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Frame = styled.iframe`
  width: 100%;
  border: none;
`;

const getHash = () => {
  if (!global.location || !global.location.hash) {
    return {};
  }
  const { hash } = global.location;
  return hash
    .slice(1)
    .split('&')
    .reduce((output, parts) => {
      const [name, value] = parts.split('=');
      return {
        ...output,
        [name]: decodeURIComponent(value),
      };
    }, {});
};

class Iframe extends Component {
  constructor(props) {
    super(props);
    const url = this.getUrl();
    this.state = {
      iFrameHeight: 1000,
      url,
    };

    if (global.history) {
      global.history.replaceState(null, null, `#url=${encodeURIComponent(url)}`);
    }

    this.onClassicMessage = this.onClassicMessage.bind(this);
  }


  componentDidMount() {
    if (global.addEventListener) {
      global.addEventListener('message', this.onClassicMessage, false);
    } else if (global.attachEvent) {
      global.attachEvent('onmessage', this.onClassicMessage);
    }
    if (global.addEventListener) {
      global.addEventListener('hashchange', this.onHashChange);
    }

    if (global.location.hash.length > 1) { // This is needed to force client side render to use hash path
      const { url } = this.state;
      const newUrl = this.getUrl();
      if (newUrl !== url) {
        this.setState({
          url: newUrl,
        });
      }
    }
  }

  componentWillUnmount() {
    if (global.addEventListener) {
      global.removeEventListener('message', this.onClassicMessage);
    } else if (global.attachEvent) {
      global.removeEventListener('onmessage', this.onClassicMessage);
    }
  }

  onClassicMessage(e) {
    const { url } = this.state;
    const { data, origin } = e;
    if (data.type === 'synthetic' && data.path) {
      global.window.scrollTo(0, 0);

      if (data.path !== url && !data.disableReload) {
        global.history.replaceState(null, null, `#url=${encodeURIComponent(e.data.path)}`);
        // NOTE: we are not updating the state here to avoid extra iframe reloads, hecne this.state.url
        // can be out of sync after initial mount
      }
    }

    if (data.type === 'scroll' && this.frame) {
      const isSmoothScrollSupported = 'scrollBehavior' in global.document.documentElement.style;
      const { top, left, behavior } = data;
      if (isSmoothScrollSupported) {
        global.window.parent.scrollTo({ top, left, behavior });
      } else {
        global.window.parent.scrollTo(left, top);
      }
    }

    if (origin.indexOf('classic') !== -1) {
      if (!data) return;

      if (data.type === 'external-nagivation' && data.url) {
        global.location.href = data.url;
      }

      if (
        data.height
        && typeof data.height === 'number'
        && Number.isFinite(data.height)
      ) {
        this.setState({ iFrameHeight: Math.ceil(data.height) });
      }
    }
  }

  onHashChange = () => {
    const newUrl = global.location.hash.substring(5);
    this.setState({
      url: newUrl,
    }, () => {
      if (this.frame) {
        this.frame.src = decodeURIComponent(newUrl);
      }
    });
  }

  getUrl = () => {
    const { url: baseUrl } = this.props;
    const {
      path = '',
      url,
      cachebuster,
    } = getHash();

    let currentUrl = `${baseUrl}${path}`;

    if (url) {
      currentUrl = url;
    }

    let busting = '';

    if (cachebuster) {
      busting = `${currentUrl.indexOf('?') >= 0 ? '&' : '?'}cachebuster=${cachebuster}`;
    }

    return currentUrl + busting;
  }

  render() {
    const { scrolling } = this.props;
    const { iFrameHeight, url } = this.state;

    return global.location ? (
      <Frame
        src={url}
        scrolling={scrolling ? 'yes' : 'no'}
        height={iFrameHeight}
        ref={(r) => { this.frame = r; }}
      />
    ) : null;
  }
}

// Proptypes
Iframe.propTypes = {
  url: PropTypes.string.isRequired,
  scrolling: PropTypes.bool,
};

Iframe.defaultProps = {
  scrolling: false,
};

// Exports
export default Iframe;
