// Import Logos
import Logo from '../assets/Logo/isp.svg';
import SquareLogo from '../assets/OGData/ISP_logo.jpg';

export default {
  // Firma Domaine
  Domaine: 'ISP.dk',
  Origin: 'https://classic.isp.dk',
  logoutUrl: 'https://classic.isp.dk/isp/log-ind/din-pension/Log-ud',
  // Primary Web Color
  PrimaryColor: '#169b62',
  PrimaryHover: '#337d58',
  PrimaryVisited: '#4f4586',
  // Logos
  DesktopLogo: Logo,
  MobileLogo: Logo,
  SquareLogo,
  // Logo Sizes
  LogoSizeMobile: '40px',
  LogoSizeTabletP: '45px',
  LogoSizeTabletL: '50px',
  LogoSizeDesktop: '75px',
  // Logo left padding
  LogoLeftMobile: '0px',
  LogoLeftTabletP: '10px',
  LogoLeftTabletL: '5px',
  LogoLeftDesktop: '0px',
  // Logo container size
  LogoContainerSize: '256px',
  // MainMenu ON/OFF
  MainMenuSeperator: true,
  // Footer Links
  FooterLinks: true,
  // Button Name (Login)
  BtnName: 'Log ind',
  // Root folder
  StartUrl: '/medlem',
  // Mobile Table  Collapse
  MobileTable: '#337d58',
  // Search enigne key
  EngineKey: '41LF4Pq8-yTGuVDc6w7y',
};
