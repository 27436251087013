import React, { createContext, Component } from 'react';
import PropTypes from 'prop-types';
import PuzzelContext from '../template/components/DesktopTools/PuzzelContext';
import LoginContextCache from './loginContextCache';

const {
  Consumer: ContextConsumer,
  Provider: ContextProvider,
} = createContext();
class ProviderWrapper extends Component {
  
  constructor() {
    super();
    this.logout = this.logout.bind(this);
    this.onMessage = this.onMessage.bind(this);
    this.defaultState =  {
      isLoggedIn: false,
      userName: null,
    };
    this.state =  this.defaultState;
  }

  componentDidMount() {
    this.setState(LoginContextCache.getCache() || this.defaultState);
    if (global.addEventListener) {
      global.addEventListener('message', this.onMessage);
    } else if (global.attachEvent) {
      global.attachEvent('onmessage', this.onMessage);
    }
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-underscore-dangle
      global._messageTestChannel = this.onMessage;
    }
  }

  componentWillUnmount() {
    if (global.addEventListener) {
      global.removeEventListener('message', this.onMessage);
    } else if (global.attachEvent) {
      global.removeEventListener('onmessage', this.onMessage);
    }
  }

  onMessage({ origin: requestOrigin, data }) {
    const { origin } = this.props;
    if (requestOrigin === origin) {
      if (!data || typeof data.login === 'undefined') {
        return;
      }
      const { puzzel } = this.props;
      const { login, name, cpr } = data;
      const state = {
        isLoggedIn: login,
        userName: name,
      };
      puzzel.setSystemData({
        enteredFormName: data.name,
        CPR: cpr,
      });
      LoginContextCache.setCache(state);
      this.setState(state);
    }
  }

  logout() {
    const { logoutUrl, onLogout } = this.props;
    const iframe = global.document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = logoutUrl;
    iframe.onload = () => {
      LoginContextCache.clearCache();
      this.setState(this.defaultState);
      // iframe.parentElement.removeChild(iframe);
      if (onLogout) {
        onLogout();
      }
    };
    global.document.body.appendChild(iframe);
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { children } = this.props;
    const { isLoggedIn, userName } = this.state;

    return (
      <ContextProvider
        value={{
          isLoggedIn,
          userName,
          logout: this.logout,
        }}
      >
        {children}
      </ContextProvider>
    );
  }
}

ProviderWrapper.propTypes = {
  origin: PropTypes.string.isRequired,
  logoutUrl: PropTypes.string.isRequired,
  onLogout: PropTypes.func,
  children: PropTypes.node.isRequired,
  puzzel: PropTypes.shape({
    setSystemData: PropTypes.func.isRequired,
  }).isRequired,
};

ProviderWrapper.defaultProps = {
  onLogout: undefined,
};

export const Consumer = ContextConsumer;
export const Provider = props => ( // eslint-disable-line react/no-multi-comp
  <PuzzelContext.Consumer>
    {({ client }) => (
      <ProviderWrapper puzzel={client} {...props} />
    )}
  </PuzzelContext.Consumer>
);
