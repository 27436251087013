import { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import CookieContext from './Context';

const Script = ({ script, type }) => {
  const { given } = useContext(CookieContext);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (given[type] && !loaded) {
      script();
      setLoaded(true);
    }
  }, [type, given, loaded]);

  return null;
};

Script.propTypes = {
  script: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default Script;
