// Import Node Modules
/* eslint-disable */
import React from 'react'
import Components from '../components';

const findRenderComponent = (data, id) => {
  if (data !== null) {
    if (typeof data === 'object' && data.componentName) {
      return renderComponent(result, `${id}_${key}`)
    }

    if (Array.isArray(data) && data.length > 0 && data[0].componentName) {
      return data.map((c, i) => renderComponent(c, `${id}_${i}`))
    }
  }

  return data
}

const renderComponent = (data, id) => {
  data.componentName = data.componentName.split('_').join('');
  const {
    componentName,
    componentData,
    componentChildren
  } = data

  const props = Object.keys(componentData).reduce((output, key, i) => {
    let result = findRenderComponent(componentData[key], id)

    return {
      ...output,
      [key]: result,
    }
  }, {})

  var children = [].concat(componentChildren || []).map((c, i) => renderComponent(c, `${id}_${i}`))

  if (!Components[componentName]) {
    throw new Error(`component ${componentName} does not exists, should be one of ${Object.keys(Components).join(', ')}`)
  }

  return React.createElement(Components[componentName], { ...props,
    key: id
  }, children);
}

export const renderPage = data => {

  try {
    const {
      pageTemplate,
      pageContent,
      pageData
    } = data

    Object.keys(pageData).map((k, i) => pageData[k] = findRenderComponent(pageData[k], `root_${k}`))

    return React.createElement(
      Components[pageTemplate], {
        data: pageData
      },
      pageContent.map((c, i) => renderComponent(c, `root_${ i }`)),
    )
  } catch (err) {
    console.error(err);
  }
}